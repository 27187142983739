import React, { useEffect, useRef, useState } from 'react';
import style from './videoPlayer.module.scss';

const VideoPlayer = ({ video, isMuted, padding, className }) => {

  const [ state, setState ] = useState({
    isMuted: isMuted,
    isPlaying: true
  })

  const handleAudio = () => {

    // console.log('turn audio ', state.isMuted ? 'on' : 'off')
    setState({
      ...state,
      isMuted: !state.isMuted
    })
  }

  const videoRef = useRef()

  const handlePlayPause = () => {
    const video = videoRef.current

    if(video) {
      if(!state.isPlaying){ 
        console.log("Start Playing")
        setState({...state, isPlaying: true })
        video.play()
      }
      else{ 
        console.log("Pause Video")
        setState({...state, isPlaying: false })
        video.pause()
      }
    }
  }

  useEffect(() => {

    const video = videoRef.current

    const setStatePlay = () => {

      setState({...state, isPlaying: true })
    }
    const setStatePause = () => {

      setState({...state, isPlaying: false })
    }

    if(video) {

      video.addEventListener('play', setStatePlay)
      video.addEventListener('pause', setStatePause)
      // video.addEventListener('end', setStatePause)
    }

    return () => {

      if(video) {
        video.removeEventListener('play', setStatePlay)
        video.removeEventListener('pause', setStatePause)
      }
    }

  }, [])

  return (
    <>
      <div className={`${style.videoPlayer} ${className}`}>
        <video
        ref={videoRef}
        src={video.filename}
        autoPlay={true}
        looped={true}
        // looped
        muted={state.isMuted}
        style={{
          padding: `${padding}vw`,
          // width: `${100}vw`,
          // height: `${100}vh`
          width: `${100}%`,
          height: `${100}%`
        }}
        // controls
        />
      </div>
      <div className={style.videoPlayerAudio} >
        {!isMuted && <div onClick={handleAudio}>
          Audio {state.isMuted ? 'on' : 'off'}
        </div>}
        <div onClick={handlePlayPause}>{state.isPlaying ? 'Pause' : 'Play'}</div>
      </div>

    </>
  )
}

export default VideoPlayer