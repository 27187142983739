import React from "react"

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Christin from "../../components/pages/christin/christin"
import AccessWrapper from '../../components/pages/AccessWrapper'


const ChristinKaiser = ({ data }) => {

  const story = data.storyblokEntry
  const content = JSON.parse(story.content)

  // console.log(content)

  const video = content.christin_video
  
  return(
    <Layout>
      <SEO title="Home" />
      <AccessWrapper introString='Christin Kaiser' content={content.artists[0]} id={0}>
        <Christin video={video}/>
      </AccessWrapper>
    </Layout>
  )
}

export default ChristinKaiser

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`