import React, { useState, useRef, Suspense }  from 'react';
import style from './christin.module.scss';
import VideoPlayer from '../../elements/videoPlayer'

function Christin({ video }) {

  console.log(video)

  return (
    <div className={style.christin}>
      <VideoPlayer video={video} isMuted={false} />
      {/* <video src={video.filename} autoPlay={true} muted={true} looped={true} /> */}
    </div>
  )
}
export default Christin;
